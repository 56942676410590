import { Suspense, lazy, useEffect } from "react";
import RoutePreloader from "./RoutePreloader";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { startLoading, finishLoading } from "../../../state/loading/loadingSlice";
import { PAGES, ValidPaths } from "../../config/pages";

interface LazyRouteProps {
  componentPath: ValidPaths;
}

function LazyRoute({ componentPath }: LazyRouteProps) {
  const dispatch = useAppDispatch();

  const LazyComponent = lazy(() => {
    return PAGES[componentPath]().catch((error) => {
      dispatch(finishLoading());
      throw error;
    });
  });

  useEffect(() => {
    dispatch(startLoading());
    return () => {
      dispatch(finishLoading());
    };
  }, [dispatch, componentPath]);

  return (
    <Suspense fallback={<RoutePreloader />}>
      <LazyComponent />
    </Suspense>
  );
}

export default LazyRoute;
