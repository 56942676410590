import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthGateway} from "@domains/auth/domain/ports/AuthGateway.ts";
import {AUTH_EVENTS} from "../events/authEvents";
import {AUTH_ERROR_MESSAGES} from "../../domain/errors/errorMessages";
import {GoogleCallbackRequest} from "../../infrastructure/types/auth";

export const handleGoogleCallbackUseCase = (authRepository: AuthGateway) =>
    createAsyncThunk(
        AUTH_EVENTS.GOOGLE_CALLBACK_SUCCESS,
        async (request: GoogleCallbackRequest) => {
            try {
                return await authRepository.handleGoogleCallback(request);
            } catch (error) {
                throw new Error(AUTH_ERROR_MESSAGES.GoogleLoginFailed);
            }
        }
    );
