import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthGateway} from "@domains/auth/domain/ports/AuthGateway.ts";
import {AUTH_EVENTS} from "../events/authEvents";
import {AUTH_ERROR_MESSAGES} from "../../domain/errors/errorMessages";

export const logoutUseCase = (authRepository: AuthGateway) =>
    createAsyncThunk(
        AUTH_EVENTS.LOGOUT_SUCCESS,
        async () => {
            try {
                return await authRepository.logout();
            } catch (error) {
                throw new Error(AUTH_ERROR_MESSAGES.LogoutFailed);
            }
        }
    );
