import {API_URL} from "@shared/infrastructure/config/api.config";
import {
    AuthGateway,
    AuthResult,
    SocialAuthResult,
} from "@domains/auth/domain/ports/AuthGateway.ts";
import {
    EmailLoginCredentials,
    GoogleCallbackRequest,
    SocialLoginRequest,
} from "../types/auth";
import {AuthError, AuthErrorCode} from "../../domain/errors/AuthError";

export class HttpAuthGateway implements AuthGateway {
    async loginWithEmail(
        credentials: EmailLoginCredentials
    ): Promise<AuthResult> {
        let response;
        try {
            response = await fetch(`${API_URL}/api/auth/login`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(credentials),
                credentials: "include",
            });
        } catch (error) {
            throw new AuthError(AuthErrorCode.NETWORK_ERROR);
        }

        if (response.status === 401) {
            throw new AuthError(AuthErrorCode.INVALID_CREDENTIALS);
        }

        if (!response.ok) {
            throw new AuthError(AuthErrorCode.NETWORK_ERROR);
        }

        return {
            authenticated: true,
        };
    }

    async logout(): Promise<AuthResult> {
        let response;
        try {
            response = await fetch(`${API_URL}/api/auth/logout`, {
                method: "POST",
                credentials: "include",
            });
        } catch (error) {
            throw new AuthError(AuthErrorCode.NETWORK_ERROR);
        }

        if (!response.ok) {
            throw new AuthError(AuthErrorCode.LOGOUT_FAILED);
        }

        return {
            authenticated: false,
        };
    }

    async checkAuthStatus(): Promise<AuthResult> {
        let response;
        try {
            response = await fetch(`${API_URL}/api/auth/status`, {
                credentials: "include",
            });
        } catch (error) {
            throw new AuthError(AuthErrorCode.NETWORK_ERROR);
        }

        if (!response.ok) {
            throw new AuthError(AuthErrorCode.STATUS_CHECK_FAILED);
        }

        const data = await response.json();
        return {
            authenticated: data.data,
        };
    }

    async initiateSocialLogin(
        request: SocialLoginRequest
    ): Promise<SocialAuthResult> {
        let response;
        try {
            const callbackUrl = `${window.location.origin}/auth/callback`;
            response = await fetch(
                `${API_URL}/api/auth/${
                    request.provider
                }/login?redirectUrl=${encodeURIComponent(callbackUrl)}`,
                {
                    method: "GET",
                    credentials: "include",
                }
            );
        } catch (error) {
            throw new AuthError(AuthErrorCode.NETWORK_ERROR);
        }

        if (!response.ok) {
            throw new AuthError(AuthErrorCode.SOCIAL_LOGIN_FAILED);
        }

        const {
            data: {redirectUrl},
        } = await response.json();

        return {
            authenticated: false,
            redirectUrl,
        };
    }

    async handleGoogleCallback(
        request: GoogleCallbackRequest
    ): Promise<AuthResult> {
        let response;
        try {
            response = await fetch(`${API_URL}/api/auth/google/callback`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(request),
                credentials: "include",
            });
        } catch (error) {
            throw new AuthError(AuthErrorCode.NETWORK_ERROR);
        }

        if (!response.ok) {
            throw new AuthError(AuthErrorCode.GOOGLE_LOGIN_FAILED);
        }

        const data = await response.json();
        return {
            authenticated: data.data,
        };
    }
}
