import {useCallback} from "react";
import {useAppDispatch} from "@shared/presentation/hooks/useAppDispatch.ts";
import {useAppSelector} from "@shared/presentation/hooks/useAppSelector.ts";
import {authActions} from "@shared/state/store.ts";
import {
    AuthResult,
    SocialAuthResult,
} from "@domains/auth/domain/ports/AuthGateway.ts";
import {
    EmailLoginCredentials,
    SocialLoginRequest,
    GoogleCallbackRequest,
} from "../../types/auth";

export const useAuth = () => {
    const dispatch = useAppDispatch();
    const authState = useAppSelector((state) => state.auth);

    const loginWithEmail = useCallback(
        async (credentials: EmailLoginCredentials): Promise<AuthResult> => {
            return dispatch(authActions.loginWithEmail(credentials)).unwrap();
        },
        [dispatch]
    );

    const initiateSocialLogin = useCallback(
        async (request: SocialLoginRequest): Promise<SocialAuthResult> => {
            return dispatch(authActions.initiateSocialLogin(request)).unwrap();
        },
        [dispatch]
    );

    const handleGoogleCallback = useCallback(
        async (request: GoogleCallbackRequest): Promise<AuthResult> => {
            return dispatch(authActions.handleGoogleCallback(request)).unwrap();
        },
        [dispatch]
    );

    const logout = useCallback(() => dispatch(authActions.logout()), [dispatch]);

    const checkAuthStatus = useCallback(
        () => dispatch(authActions.checkAuthStatus()),
        [dispatch]
    );

    return {
        ...authState,
        loginWithEmail,
        initiateSocialLogin,
        handleGoogleCallback,
        logout,
        checkAuthStatus,
    };
};
