export const authTranslations = {
  fr: {
    login: {
      title: "Bienvenue sur Tablow !",
      emailStepTitle: "Bienvenue sur Tablow !",
      emailStepSubtitle: "Prêt à créer quelque chose d'incroyable ?",
      passwordStepTitle: "Ravi de vous revoir !",
      passwordStepSubtitle: "Plus qu'une étape et c'est parti !",
      email: "Adresse e-mail",
      password: "Mot de passe",
      submit: "Se connecter",
      continue: "Continuer",
      back: "Retour",
      rememberMe: "Se souvenir de moi",
      forgotPassword: "Mot de passe oublié ?",
      continueWithGoogle: "Continuer avec Google",
      continueWithApple: "Continuer avec Apple",
      or: "Ou",
    },
    logout: {
      button: "Se déconnecter",
    },
    menu: {
      profile: "Mon profil",
      settings: "Paramètres",
      logout: "Se déconnecter",
    },
    errors: {
      invalidCredentials: "Identifiants invalides",
      logoutFailed: "Erreur lors de la déconnexion",
      statusCheckFailed: "Erreur lors de la vérification du statut",
      networkError: "Erreur de connexion",
      unknownError: "Une erreur inconnue est survenue",
      googleLoginFailed: "La connexion avec Google a échoué",
      socialLoginFailed: "La connexion sociale a échoué",
    },
  },
} as const;
