import {createSlice} from "@reduxjs/toolkit";
import {AuthGateway} from "@domains/auth/domain/ports/AuthGateway.ts";
import {emailLoginUseCase} from "../application/useCases/emailLoginUseCase";
import {socialLoginUseCase} from "../application/useCases/socialLoginUseCase";
import {handleGoogleCallbackUseCase} from "../application/useCases/handleGoogleCallbackUseCase";
import {logoutUseCase} from "../application/useCases/logoutUseCase";
import {checkAuthStatusUseCase} from "../application/useCases/checkAuthStatusUseCase";
import {AuthState} from "./types";

const initialState: AuthState = {
    isAuthenticated: false,
    isLoading: false,
    error: null,
};

export const createAuthSlice = (authRepository: AuthGateway) => {
    const loginWithEmail = emailLoginUseCase(authRepository);
    const initiateSocialLogin = socialLoginUseCase(authRepository);
    const handleGoogleCallback = handleGoogleCallbackUseCase(authRepository);
    const logout = logoutUseCase(authRepository);
    const checkAuthStatus = checkAuthStatusUseCase(authRepository);

    const slice = createSlice({
        name: "auth",
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            // Email Login
            builder.addCase(loginWithEmail.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            });
            builder.addCase(loginWithEmail.fulfilled, (state, {payload}) => {
                state.isAuthenticated = payload.authenticated;
                state.isLoading = false;
                state.error = null;
            });
            builder.addCase(loginWithEmail.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.isLoading = false;
                state.error = action.error.message || null;
            });

            // Social Login
            builder.addCase(initiateSocialLogin.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            });
            builder.addCase(initiateSocialLogin.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            });
            builder.addCase(initiateSocialLogin.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || null;
            });

            // Google Callback
            builder
                .addCase(handleGoogleCallback.pending, (state) => {
                    state.isLoading = true;
                    state.error = null;
                })
                .addCase(handleGoogleCallback.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isAuthenticated = action.payload.authenticated;
                    state.error = null;
                })
                .addCase(handleGoogleCallback.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isAuthenticated = false;
                    state.error = action.error.message || "Une erreur est survenue";
                });

            // Logout
            builder.addCase(logout.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            });
            builder.addCase(logout.fulfilled, (state) => {
                state.isAuthenticated = false;
                state.isLoading = false;
                state.error = null;
            });
            builder.addCase(logout.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || null;
            });

            // Check Auth Status
            builder.addCase(checkAuthStatus.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            });
            builder.addCase(checkAuthStatus.fulfilled, (state, {payload}) => {
                state.isAuthenticated = payload.authenticated;
                state.isLoading = false;
                state.error = null;
            });
            builder.addCase(checkAuthStatus.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.isLoading = false;
                state.error = action.error.message || null;
            });
        },
    });

    return {
        reducer: slice.reducer,
        actions: {
            ...slice.actions,
            loginWithEmail,
            initiateSocialLogin,
            handleGoogleCallback,
            logout,
            checkAuthStatus,
        },
    };
};
