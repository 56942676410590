import { AuthResult } from "@domains/auth/domain/ports/AuthGateway.ts";

export const AUTH_EVENTS = {
  LOGIN_SUCCESS: "auth/loginSuccess",
  LOGIN_FAILED: "auth/loginFailed",
  LOGOUT_SUCCESS: "auth/logoutSuccess",
  CHECK_AUTH_STATUS: "auth/checkStatus",
  EMAIL_LOGIN_SUCCESS: "auth/emailLoginSuccess",
  SOCIAL_LOGIN_INITIATED: "auth/socialLoginInitiated",
  GOOGLE_CALLBACK_SUCCESS: "auth/googleCallbackSuccess",
} as const;

export interface AuthEvent {
  type: keyof typeof AUTH_EVENTS;
  payload: AuthResult;
}
