import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";

export function AuthInitializer({ children }: { children: React.ReactNode }) {
  const { checkAuthStatus } = useAuth();

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  return <>{children}</>;
}
