import { configureStore } from "@reduxjs/toolkit";
import { loadingReducer } from "./loading/loadingSlice";
import { HttpAuthGateway } from "@domains/auth/infrastructure/repositories/HttpAuthGateway.ts";
import { createAuthSlice } from "../../domains/auth/state/authSlice";

// Repositories
const authRepository = new HttpAuthGateway();

// Slices
const authSlice = createAuthSlice(authRepository);

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    auth: authSlice.reducer,
    // Autres reducers ici
  },
});

// Actions exportées
export const authActions = authSlice.actions;

// Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
