export class AuthError extends Error {
    constructor(public readonly code: AuthErrorCode) {
        super();
        this.name = "AuthError";
    }
}

export enum AuthErrorCode {
    INVALID_CREDENTIALS = "invalidCredentials",
    LOGOUT_FAILED = "logoutFailed",
    STATUS_CHECK_FAILED = "statusCheckFailed",
    NETWORK_ERROR = "networkError",
    GOOGLE_LOGIN_FAILED = "googleLoginFailed",
    SOCIAL_LOGIN_FAILED = "socialLoginFailed",
}
