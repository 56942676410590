import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export function GoogleAuthCallback() {
  const navigate = useNavigate();
  const { handleGoogleCallback } = useAuth();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        console.log("Starting Google callback handling..."); // Debug log
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);

        const accessToken = params.get("access_token");
        if (!accessToken) {
          throw new Error("No access token found");
        }

        console.log("Got access token, calling handleGoogleCallback..."); // Debug log
        await handleGoogleCallback({
          accessToken,
          refreshToken: params.get("refresh_token") ?? undefined,
          providerToken: params.get("provider_token") ?? undefined,
        });

        console.log("Callback successful, navigating to dashboard..."); // Debug log
        navigate("/dashboard");
      } catch (error) {
        console.error("Erreur lors du callback Google:", error);
        setError(
          error instanceof Error ? error.message : "Une erreur est survenue"
        );
        setTimeout(() => navigate("/login"), 3000);
      }
    };

    handleCallback();
  }, [navigate, handleGoogleCallback]);

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-500 mb-4">{error}</p>
        <p className="text-gray-500">
          Redirection vers la page de connexion...
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900 mb-4"></div>
      <p className="text-gray-700">Authentification en cours...</p>
    </div>
  );
}
