// Mapping statique des pages de l'application
export const PAGES = {
  "auth/Login": () =>
    import("../../../domains/auth/infrastructure/presentation/pages/Login"),
  "tablow/Dashboard": () =>
    import("../../../domains/dashboard/presentation/pages/Dashboard"),
  "tablow/TablowDetail": () =>
    import("../../../domains/tablow/presentation/pages/[id]"),
} as const;

export type ValidPaths = keyof typeof PAGES;
