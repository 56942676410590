import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthGateway} from "@domains/auth/domain/ports/AuthGateway.ts";
import {AUTH_EVENTS} from "../events/authEvents";
import {AUTH_ERROR_MESSAGES} from "../../domain/errors/errorMessages";
import {SocialLoginRequest} from "../../infrastructure/types/auth";

const handleSocialAuthResult = async (
    authRepository: AuthGateway,
    request: SocialLoginRequest
) => {
    return await authRepository.initiateSocialLogin(request);
};

export const socialLoginUseCase = (authRepository: AuthGateway) =>
    createAsyncThunk(
        AUTH_EVENTS.SOCIAL_LOGIN_INITIATED,
        async (request: SocialLoginRequest) => {
            try {
                return await handleSocialAuthResult(authRepository, request);
            } catch (error) {
                throw new Error(AUTH_ERROR_MESSAGES.SocialLoginFailed);
            }
        }
    );
