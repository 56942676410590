import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthGateway} from "@domains/auth/domain/ports/AuthGateway.ts";
import {AUTH_EVENTS} from "../events/authEvents";
import {AUTH_ERROR_MESSAGES} from "../../domain/errors/errorMessages";

export const checkAuthStatusUseCase = (authRepository: AuthGateway) =>
    createAsyncThunk(
        AUTH_EVENTS.CHECK_AUTH_STATUS,
        async () => {
            try {
                return await authRepository.checkAuthStatus();
            } catch (error) {
                throw new Error(AUTH_ERROR_MESSAGES.StatusCheckFailed);
            }
        }
    );
