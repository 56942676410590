import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "./types";

const initialState: LoadingState = {
  isLoading: false,
  progress: 0,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
      state.progress = 0;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    finishLoading: (state) => {
      state.isLoading = false;
      state.progress = 100;
    },
  },
});

export const { startLoading, setProgress, finishLoading } =
  loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
