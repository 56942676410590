import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthGateway} from "@domains/auth/domain/ports/AuthGateway.ts";
import {AUTH_EVENTS} from "../events/authEvents";
import {AUTH_ERROR_MESSAGES} from "../../domain/errors/errorMessages";
import {EmailLoginCredentials} from "../../infrastructure/types/auth";

const handleEmailAuthResult = async (
    authRepository: AuthGateway,
    credentials: EmailLoginCredentials
) => {
    return await authRepository.loginWithEmail(credentials);
};

export const emailLoginUseCase = (authRepository: AuthGateway) =>
    createAsyncThunk(
        AUTH_EVENTS.EMAIL_LOGIN_SUCCESS,
        async (credentials: EmailLoginCredentials) => {
            try {
                return await handleEmailAuthResult(authRepository, credentials);
            } catch (error) {
                throw new Error(AUTH_ERROR_MESSAGES.InvalidCredentials);
            }
        }
    );
