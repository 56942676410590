import { Routes, Route, Navigate } from "react-router-dom";
import LazyRoute from "./components/loading/LazyRoute";
import { ProtectedRoute } from "../../domains/auth/infrastructure/presentation/components/ProtectedRoute";
import { I18nProvider } from "./../presentation/i18n/I18nProvider";
import { GoogleAuthCallback } from "@domains/auth/infrastructure/presentation/components/GoogleAuthCallback";

function App() {
  return (
    <I18nProvider>
      <Routes>
        <Route
          path="/login"
          element={<LazyRoute componentPath="auth/Login" />}
        />
        <Route path="/auth/callback" element={<GoogleAuthCallback />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <LazyRoute componentPath="tablow/Dashboard" />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route
          path="/tablow/:id"
          element={<LazyRoute componentPath="tablow/TablowDetail" />}
        />
      </Routes>
    </I18nProvider>
  );
}

export default App;
