import { PropsWithChildren, useEffect, useState } from 'react';
import i18n from './config';

export function I18nProvider({ children }: PropsWithChildren) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const initI18n = async () => {
      await i18n.init();
      setIsLoaded(true);
    };

    initI18n();
  }, []);

  if (!isLoaded) {
    return null; // ou un loader si vous préférez
  }

  return <>{children}</>;
} 